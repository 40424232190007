<template>
	<div class="FM_page-whiteList">
		<div class="container" v-if="spinner">
			<div class="row">
				<div class="col">
					<div class="d-flex justify-content-center" style="padding: 30px 0;">
						<div class="spinner-border text-light" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container" v-if="!spinner">
			<div class="row">
				<div class="col-12">
					<div class="FM_table-fund table-portfolio table-responsive">
						<h6>WHITELIST</h6>
						<table class="table table-striped table-borderless">
							<tbody>
							<tr>
								<th scope="row">STATUS</th>
								<th></th>
								<th>NAME</th>
								<th>EMAIL</th>
								<th>CODE</th>
							</tr>
<!--							:key="item.code"-->
							<tr v-if="whitelist.length > 0" v-for="(item, key) in whitelist" >
								<td>
									<span class="mdi mdi-18px mdi-checkbox-marked-circle FM_text-green1" v-if="item.status === 1"></span>
									<span class="mdi mdi-18px mdi-checkbox-marked-circle-outline" v-if="item.status === 2"></span>
									<span class="mdi mdi-18px mdi-close-circle-outline" v-if="item.status === 0"></span>
								</td>
								<td>
									<span class="FM_text-green1 btn-link" v-clipboard:copy="registerUrl + item.code" v-clipboard:success="onCopy" v-clipboard:error="onError">Copy</span>
								</td>
								<td>{{ item.name }}</td>
								<td>{{ item.email }}</td>
								<td>{{ item.code }}</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-12 col-lg-2 offset-lg-5">
					<button type="button" class="btn btn-block btn-success FM_btn-add" data-toggle="modal" data-target="#FM_modal-addWhiteList" @click="resetValidateError">
						Add a Believer
					</button>
				</div>
				<div class="col-12">
					<pagination class="pagination justify-content-center FM_pagination" v-model="pagination.currentPage" :records="originWhitelist.length" first-text="First" @paginate="changePagination"/>
				</div>
			</div>
		</div>
		<ValidationObserver v-slot="{ handleSubmit }" ref="form">
			<form class="modal fade FM_style-login" id="FM_modal-addWhiteList" tabindex="-1" aria-hidden="true" @submit.prevent="handleSubmit(submit)">
				<div class="modal-dialog modal-sm">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Add a Believer</h5>
						</div>
						<div class="modal-body">
							<p>The Believer will automatically receive a verification email to register for an account.</p>
							<div class="alert alert-danger" role="alert" v-show="formStatus.success === 0">
								{{ formStatus.errorMsg }}
							</div>
							<div class="form-group">
								<ValidationProvider rules="required" name="Name" v-slot="{ errors }">
									<input type="text" v-model="form.name" class="form-control FM_inputStyle-circle" :class="{ 'invalid': errors.length > 0 }" :disabled="formStatus.spinner" placeholder="Name">
									<span class="text-danger FM_input-error">{{ errors[0] }}</span>
								</ValidationProvider>
							</div>
							<div class="form-group">
								<ValidationProvider rules="required|email" name="Email" v-slot="{ errors }">
									<input type="text" v-model="form.email" class="form-control FM_inputStyle-circle" :class="{ 'invalid': errors.length > 0 }" :disabled="formStatus.spinner" :placeholder="$t('__email')">
									<span class="text-danger FM_input-error">{{ errors[0] }}</span>
								</ValidationProvider>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-block btn-success FM_btn" :disabled="formStatus.spinner">
								<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="formStatus.spinner"></span>
								Add
							</button>
							<button type="button" class="btn btn-block btn-link FM_btn text-light" data-dismiss="modal">Cancel</button>
						</div>
					</div>
				</div>
			</form>
		</ValidationObserver>
	</div>
</template>

<script>
import { Ajax } from "@/api/index.js"
import { API } from "@/api/api.js"
import { urlReplace } from "@/utils/index.js"
import { extend } from 'vee-validate'
import { required, email, min } from 'vee-validate/dist/rules'
import { deepCopy } from "../../utils";

export default {
	name: "WhiteList",
	data() {
		return{
			spinner: true,
			registerUrl: process.env.VUE_APP_URL + '/account/verify/',
			originWhitelist: [],
			whitelist: [],
			form: {
				name: '',
				email: ''
			},
			formStatus: {
				spinner: false,
				success: null,
				errorMsg: '',
			},
			pagination: {
				data: [],
				perPage: 3,
				currentPage: 1,
			}
		};
	},
	created() {
		extend('email', email);
		extend('required', required);

		this.getData();
	},
	methods: {
		/*
		 * clipboard
		 */
		onCopy: function (e) {
			alert('You just copied: ' + e.text)
		},
		onError: function (e) {
			alert('Failed to copy texts')
		},
		resetStatus() {
			this.formStatus = {
				spinner: false,
				success: null,
				errorMsg: ''
			};
		},
		changePagination() {
			let begin = (this.pagination.currentPage - 1) * 25;
			let end = this.pagination.currentPage * 25;
			this.whitelist = this.originWhitelist.slice(begin, end);
		},
		getData() {
			this.spinner = true;
			Ajax(
				API.getWhitelist.method,
				API.getWhitelist.url
			).then(result => {
				this.originWhitelist = result.data;
				this.whitelist = this.originWhitelist.slice(0, 25);
				this.spinner = false;
			});
		},
		resetValidateError() {
			this.form.email = '';
			this.form.name = '';
			this.$refs.form.reset();
		},
		submit() {
			this.resetStatus();
			this.$Progress.start();
			this.formStatus.spinner = true;
			let _data = {
				name: this.form.name,
				email: this.form.email
			};

			Ajax(
				API.addWhitelist.method,
				API.addWhitelist.url,
				_data
			).then(result => {
				this.formStatus.spinner = false;

				if (result.success !== 1) {
					this.formStatus.errorMsg = result.error_msg;
					return;
				}
				
				$('#FM_modal-addWhiteList').modal('hide');
				this.getData();
			});
		}
	}
}
</script>

<style scoped>

</style>